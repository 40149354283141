import React from "react";
import '../styles/long1.css';
import '../styles/neverusers.css';
// const IndexPage = () => (
//     <head>
//     <meta http-equiv="refresh" content="1; url='/summer-sweeps-end'" />
//   </head>
//   );

// export default IndexPage;

const IndexPage = () => (
    <div className="grid-container">
        <div className="header">
            <div className="header1">
                <div className="logo-div go-logo-div">
                    <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
                </div>
            </div>
            <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
            <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
            <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
        </div>
        <div className="left_space"></div>
        <div className="short1-main">
            <div className="sw-bg">
    <div className="go-headSection">
    <div className="go-leftSection">
    <p className="sw-header"><br/><br/><br/></p>
    <p className="sw-subtext">Enter now to see if you qualify for<br/> 90% off tolls in September, exclusively on<br/> the LBJ, NTE & NTE 35W TEXpress Lanes.</p>
    </div>
        
                    <div className="go-rightSection">
                        <div id='lightbox-inline-form-3def76ab-68e0-434e-985a-e384e4783966' className="gopage-form"></div>
                    </div>
                </div>

            </div>
            <div className="gogogo-main2">
                <div className="go-section2">
                    <div className="go-section2-left">
                        <div className="redline-img"></div>
                        <p className="go-section2-left-text">What Are The LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                    </div>
                    <div className="go-section2-right">
                        <p className="go-section2-right-text">The LBJ/NTE/NTE 35W TEXpress Lanes are unique toll lanes built within existing highways to accommodate more traffic volume. Unlike traditional toll roads, the price changes based on real-time traffic conditions in order to reduce congestion and keep everyone moving at least 50 MPH.</p>
                    </div>
                </div>
                <div className="go-section2">
                    <div className="go-section2-left">
                        <div className="redline-img"></div>
                        <p className="go-section2-left-text">How Can I Use the LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                    </div>
                    <div className="go-section2-right">
                        <p className="go-section2-right-text">Got a TollTag, TxTag or EZ TAG? You're ready to use the LBJ/NTE/NTE 35W TEXpress Lanes and enter the sweepstakes promotion!</p>
                    </div>
                </div>
            </div>
            <div className="gogogo-main4">
                <div className="go-main4-padding">
                    <p className="sw-main4-header">Where are the LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                    <p className="sw-small-text">North Tarrant Express (I-820, SH 183 and SH 121I-35W) &amp; LBJ Express (I-635)</p>
                    <div className="map-img"></div>
                    <p className="sw-small-text">Promotion valid only on the LBJ, NTE & NTE 35W TEXpress Lanes highlighted blue on the map.</p>
                </div>
            </div>
            <div className="sw-main4">
                <div className="register-box">
                    <p id="register-box-header" className="register-box-text">Where is my toll transponder number?</p>
                    <p className="register-box-text">Here are two ways you can locate your toll transponder number:</p>
                    <div className="register-bot-img"></div>
                    <p className="register-box-text">Refer to the images above to find the information printed on your toll tag transponder on your vehicle's windshield. Your toll transponder number is printed on the side facing the driver in the vehicle.</p><br />
                    <p className="register-box-text">You can also find your toll transponder number by logging into your respective toll transponder account:</p>
                    <div className="register-box-link"><a href="https://csc.ntta.org/olcsc/" target="_blank">Visit My TollTag Account</a>    <span className="mobile-line">|</span>    <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">Visit My TxTag Account</a>    <span className="mobile-line">|</span>    <a href="https://www.hctra.org/Login" target="_blank">Visit My EZ TAG Account</a></div>
                </div>
            </div>
        </div>
        <div className="right_space"></div>
        <div className="footer">
            <div className="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img className="fb-icon"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" /></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" /></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2021 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
    </div>
);

export default IndexPage;
